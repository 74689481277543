import s from './MobileHeader.module.scss'
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
export default function MobileHeader({currentLink}) {
    const links = [
    
    
        { 
                name : 'главная',
                link : '#main'
        },
        { 
            name : 'программа практикума',
            link : '#program'
        },
        { 
            name : 'варианты участия',
            link : '#variants'
        },
        { 
            name : 'результаты',
            link : '#results'
        },
        { 
            name : 'эксперт',
            link : '#expert'
        },
       
        { 
            name : 'отзывы',
            link : '#reviews'
        },
        { 
            name : 'faq',
            link : '#faq'
        },]
    const [isOpen,setIsOpen] = useState(false)
    const Up = () => {
        window.scrollTo( {
            top : 0,
            behavior : 'smooth',
        })
    }
    return(
        <div className={s.header}>
           <div className={s.title}>
                <img onClick={Up} src="/img/icons/angel.svg" alt="" />
                <p>Маг Дыхания</p>
               {!isOpen ? (
                 <MenuIcon onClick = {() => setIsOpen(true)} className={s.burger}/>
               ) : (
                <CloseIcon onClick = {() => setIsOpen(false)} className={s.burger}/>
               )}
           </div>
         {isOpen && (
              <div className={s.links}>
                {links.map((i,idx) => (
                    <div key={`mobileLinkN${idx}`}>
                        <a onClick={() => setIsOpen(false)} className={currentLink === i.name? s.activeLink : s.link} href={i.link}>{i.name}</a>
                    </div>
                ))}
              </div>
         )}
        </div>
    )
}