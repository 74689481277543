import ReactPlayer from "react-player/lazy";
import Slick from "react-slick";
import s from "./ReviewVideos.module.scss";
import videos from "../../fake-data/videos.json";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
export default function ReviewVideos() {
  const settings = {
    infinite: true,
    speed: 900,
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  return (
    <div id="reviews" className={s.container}>
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <h3 className={s.title}>Видеоотзывы участников</h3>
      <div className={s.SlideContainer}>
        <Slick
          customPaging={(i) => (
            <div className={"dots-pag"}>
              <div className={"activate"} />
            </div>
          )}
          dotsClass={"slick-dots"}
          {...settings}
        >
          {videos.map((info, idx) => {
            return (
              <div key={`VideoN${idx}`} className={s.video}>
                <ReactPlayer
                  url={info.url}
                  className="react-player-review"
                  playing={true}
                  light={true}
                  controls={true}
                  playIcon={
                    <div className={s.PlayCircle}>
                      <PlayArrowIcon className={s.PlayArrow} />
                    </div>
                  }
                />
              </div>
            );
          })}
        </Slick>
      </div>
    </div>
  );
}
