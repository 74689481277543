import {useEffect, useState} from 'react'
import s from './Variants.module.scss'
import BuyModal from '../../../module/BuyModal/BuyModal';
import { useInView } from 'react-cool-inview';
export default function Variants({setCurrentLink}) {
const gcpc = localStorage.getItem('gcpc')
    const {observe } = useInView({
        threshold: 0.25,
        onChange: ({ observe, }) => {

          observe();
        },
        onEnter: () => {
          setCurrentLink('варианты участия')
        },
      });

    const [isOpen,setIsOpen]=useState(false)
    const [url,setUrl] = useState()
    const order = (url) => {
        setIsOpen(true)
        setUrl(url)
    }



    return(
        <>
        <h2 ref={observe} className={s.title}>Варианты участия</h2><div id='variants' className={s.container}>
            <div className={s.Card}>

                <div className={s.wrapper}>
                    <div className={s.relative}>
                        <div style={{ 'backgroundImage': `url(${'/img/adept.png'})` }} className={s.img}>

                        </div>

                    </div>
                    <div className={s.about}>
                        <p>Пакет “9 модулей” (практики в записи)</p>
                        <h3>Адепт</h3>
                        <ul>
                            <li>
                                Без обратной связи от кураторов
                            </li>
                            <li>
                                Доступ к материалам курса на 1.5 года
                            </li>
                            <li>
                             Чат для общения в Телеграм
                            </li>

                        </ul>
                    </div>
                    <div className={s.bonus}>
                        <h3>
                            + БОНУС
                        </h3>
                        <ul>
                            <li>
                            Дополнительная практика «Освобождение от влияния прошлых отношений и связей»
                            </li>
                        </ul>
                    </div>
                    <div className={s.price}>
                            <p className={s.oldPrice}>60 000 р.</p>
                            <p>49 999 р.</p>
                        </div>
                    <button onClick={() => order(`https://school.energybreathing.ru/pl/lite/widget/widget?&id=762924?&gcpc=${gcpc}`)}>
                        ЗАКАЗАТЬ
                    </button>
                    {/*<button id={"596092df69b322b043df5e5dfd09d92baed18088"} onClick={click}>*/}
                    {/*    ЗАКАЗАТЬ*/}
                    {/*</button>*/}
                    {/*<a href={"https://t.me/+0g02uRaWN39iNTQy"} >*/}
                    {/*    ЗАКАЗАТЬ*/}
                    {/*</a>*/}
                </div>
            </div>
            <div className={s.Card}>
                <div className={s.wrapper}>
                    <div className={s.relative}>
                        <div style={{ 'backgroundImage': `url(${'/img/magistr.png'})` }} className={s.img}>

                        </div>

                    </div>
                    <div className={s.about}>
                        <p>Все, что входит в пакет “Адепт”</p>
                        <h3>Магистр</h3>

                        <ul>
                            <li>
                                Обратная связь и сопровождение кураторов
                            </li>
                            <li>
                             9 групповых онлайн встреч в Zoom с куратором.
                            </li>
                            <li>
                                Разбор личных ситуаций и помощь в проработке блоков.
                            </li>
                            <li>
                                Доступ к материалам курса на 1.5 года.
                            </li>
                            <li>
                                Отдельный чат с куратором - тренером по энергодыханию.
                            </li>
                            <li>
                                Книга «Маг дыхания» (аудиоверсия книги)
                            </li>

                        </ul>
                    </div>
                    <div className={s.bonus}>
                        <h3>
                            + БОНУС
                        </h3>
                        <ul>
                            <li>
                            Мощная практика холотропного дыхания для освобождения от психологических травм прошлого. Аудиоформат сеанса на 68 минут.
                            </li>
                            <li>
                            Эксклюзивная 20-минутная практика в аудиоформате для ежедневного включения ресурсных состояний (перед переговорами, быстрое восстановление после жесткой психологической атаки, мгновенное снятие усталости)
                            </li>
                        </ul>
                    </div>
                    <div className={s.price}>

                           <p className={s.oldPrice}>90 000 р.</p>
                           <p>88 888 р.</p>
                       </div>
                       <button onClick={() => order(`https://school.energybreathing.ru/pl/lite/widget/widget?&id=762930?&gcpc=${gcpc}`)}>
                           ЗАКАЗАТЬ
                        </button>

                    {/*<a href={"https://t.me/+0g02uRaWN39iNTQy"} >*/}
                    {/*    ЗАКАЗАТЬ*/}
                    {/*</a>*/}

                </div>
            </div>
            <div className={s.Card}>
                <div className={s.wrapper}>
                    <div className={s.relative}>
                        <div style={{ 'backgroundImage': `url(${'/img/Oracle.png'})` }} className={s.img}>

                        </div>

                    </div>
                    <div className={s.about}>
                        <p>Все, что входит в пакет Магистр</p>
                        <h3>Магистр X2</h3>

                        <ul>
                            <li>
                                 Обратная связь и сопровождение кураторов и Романа
                            </li>
                            <li>
                                 9 групповых онлайн встреч в Zoom с Романом
                            </li>
                            <li>
                                Доступ к материалам курса на 1.5 года
                            </li>
                            <li>
                                Отдельный чат с Романом
                            </li>
                            <li>
                                Книга «Маг дыхания» (аудиоверсия книги)
                            </li>
                        </ul>
                    </div>
                    <div className={s.bonus}>
                        <h3>
                            + БОНУС
                        </h3>
                        <ul>
                            <li>
                            Мощная практика холотропного дыхания для освобождения от психологических травм прошлого. Аудиоформат сеанса на 68 минут.
                            </li>
                            <li>
                            Эксклюзивная 20-минутная практика в аудиоформате для ежедневного включения ресурсных состояний (перед переговорами, быстрое восстановление после жесткой психологической атаки, мгновенное снятие усталости)
                            </li>
                            <li>
                            “7 техник на каждую сферу” (чакру) в аудио формате энергодыхания для самостоятельной работы и усиления всех практик
                            </li>
                        </ul>
                    </div>
                         <div className={s.price}>

                            <p className={s.oldPrice}>250 000 р.</p>
                            <p>155 555 р.</p>
                        </div>
                        <button onClick={() => order(`https://school.energybreathing.ru/pl/lite/widget/widget?&id=762932?&gcpc=${gcpc}`)}>
                            ЗАКАЗАТЬ
                        </button>
                    {/*<a href={"https://t.me/+0g02uRaWN39iNTQy"} >*/}
                    {/*    ЗАКАЗАТЬ*/}
                    {/*</a>*/}
                </div>
            </div>
            {isOpen && <BuyModal gcpc = {gcpc} url={url} close={setIsOpen} />}
        </div></>
    )

}
