import s from './BuyModal.module.scss'
import CloseIcon from '@mui/icons-material/Close';
export default function BuyModal ({close,url,gcpc}) {

    return (
        <div className={s.Modal} onClick={() => close(false)}>
        <div className={s.ModalContent} onClick={(e) => e.stopPropagation()}>
                <iframe title='sdf' src={url} frameborder="0">
                    
                </iframe>
                <CloseIcon className={s.close} onClick={() => close(false)}/>
        </div>
    </div>
    )
}