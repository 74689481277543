import s from './Footer.module.scss'

export default function Footer() {
//
    return(
        <footer className={s.footer}>
              <div className={s.social}>
                            <img src={'/img/icons/orangeTelegram.svg'} alt={'telegram'}/>
                            <a target={'_blank'} rel={'noreferrer'} href="https://vk.com/practica_for_me">
                                <img src={'/img/icons/orangeVK.svg'} alt={'vk'}/>
                            </a>
                    </div>
            <div className={s.security}>
                    <p>© Все права защищены.</p>
                    <p>ИП Карловский Роман Николаевич</p>
                    <p>ОГРН: 315400400002817</p>
                    <a target={'_blank'} rel={'noreferrer'}  href="https://school.energybreathing.ru/oferta">Договор оферты</a>
            </div>
        </footer>
    )
}