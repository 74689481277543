import s from './styles/practic.module.scss'
import Header from './module/Header/Header';
import PracticComponent from './components/Practic/PracticComponent';
import TelegramIcon from '@mui/icons-material/Telegram';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Footer from './module/Footer/Footer';
import { useEffect, useState } from 'react';
function App() {
  const [currentLink,setCurrentLink] = useState()
  const Up = () => {
    window.scrollTo( {
        top : 0,
        behavior : 'smooth',
    })
}
const queryParams = new URLSearchParams(window.location.search)
const gcpc = queryParams.get('gcpc')
useEffect(() => {
if (gcpc) {
  localStorage.setItem('gcpc',gcpc)
}
// eslint-disable-next-line react-hooks/exhaustive-deps
},[])
  return (
    <div className="App">
       <div className={s.fixed}>

      <div className={s.linkSocial} >
          <a href="https://t.me/alexafa03" target={'_blank'} rel='noreferrer'><TelegramIcon className={`${s.phoneIcon} ${s.tg}`}/></a>
          <a href="https://wa.me/79675737695?text=Здравствуйте%2C+у+меня+есть+вопрос" target={'_blank'} rel='noreferrer'><LocalPhoneIcon className={s.phoneIcon}/></a>
          <a href='#asds' onClick={Up}  rel='noreferrer'><ArrowForwardIosIcon style={{'transform' : 'rotate(-90deg)'}} className={s.phoneIcon}/></a>
      </div>

      </div>
     <Header currentLink ={currentLink}/>
     <PracticComponent  setCurrentLink ={setCurrentLink}/>
     <Footer/>
    </div>
  );
}

export default App;
