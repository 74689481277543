
import {    useForm } from 'react-hook-form';
import s from './OrderModal.module.scss'
import axios from 'axios';
export default function OrderModal ({close}) {
 const sendOffer = (info) => {
  const data = {
  "message": `Клиент по имени ${info.name} оставил заявку. Его почта : ${info.mail}. Его телефон для связи : ${info.phone} `,
  "subject": "Заявка из сайта localhost" ,
  "recipient": "energy@energybreathing.ru"
};

const config = {
  method: 'post',
  url: 'https://functions.yandexcloud.net/d4eotiv97a5oi6bcmasg?token_jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZW5kZXIiOiJkZXZAZW5lcmd5YnJlYXRoaW5nLnJ1IiwicGFzc3dvcmQiOiIyRHZtekxDTSIsInNtdHAiOiJzbXRwLnRpbWV3ZWIucnUiLCJwb3J0IjoyNTI1fQ.JQHnxl3omisnKabpqm7WYjQ6dTI8U5N1oogyo5DAUbQ',
  headers: { 
    'Content-Type': 'application/json'
  },
  data : data
};

axios(config)
.then(function (response) {
})
.catch(function (error) {
});
reset()
close()
 }
    const {
        register,
        handleSubmit,
        reset
      } = useForm({
        mode: "onSubmit"
      });
     
    return (
        <div className={s.Modal} onClick={() => close(false)}>
        <div className={s.ModalContent} onClick={(e) => e.stopPropagation()}>
                <div className={s.bg}>
                        <h3>маг дыхания</h3>
                        <p>дыхательные  медитации  по полному выходу из стресса
                            <br />
                        и восстановлению организма</p>
                </div>
                <div className={s.form}>
                        <h3>Чтобы зафиксировать стоимость и задать <br/> интересующий вас вопрос, оставьте заявку</h3>
                        <form onSubmit={handleSubmit((data) => sendOffer(data))}>
                            <input {...register('name')} required placeholder='Ф.И.О'  type="text" />
                            <input {...register('mail')} required placeholder='E-mail' type="email" />
                            <input {...register('phone')} required placeholder='Телефон' type="text" />
                            <button type='submit'> оставить заявку</button>
                        </form>
                </div>
        </div>
    </div>
    )
}