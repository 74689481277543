import s from "./breathMagician.module.scss"
import { useInView } from 'react-cool-inview';

const BreathMagician = ({setIsOpen,setCurrentLink}) => {

    const { observe } = useInView({
        threshold: 0.25,
        onChange: ({ observe, }) => {

          observe();
        },
        onEnter: () => {
          setCurrentLink('главная')
        },
      });

    return (
        <section ref={observe} id="main" className={s.breathMagician}>
            <div className="container">

                <div className={s.wrap}>
                    <img alt="logo" className={s.logo} src="/img/icons/angel.svg"/>
                    <div className={s.subTitle}>
                        <p>Легендарный практикум Романа Карловского</p>
                    </div>
                    <div className={s.mainInfo}>
                        <div className={s.title}>

                           <p> Маг Дыхания</p>
                        </div>
                        <div className={s.text}>
                           <p> Овладейте методом управления реальностью, чтобы ясно <br/> мыслить,
                            быть в ресурсном состоянии и достигать смелых целей</p>
                        </div>
                        <p className={s.start}></p>
                    </div>
                    <div className={s.btnWrap}>
                        <a href="#variants"  className={s.btn}>Записаться на курс</a>
                    </div>
                </div>


            </div>
        </section>
    );
};

export default BreathMagician;
