import BreathMagician from "./breathMagician/breathMagician";
import Result from "./Result/Result";
import Expert from "./Expert/Expert";
import HowItWork from "./howItWork/howItWork";
import Video from './Video/Video';
import Variants from './Variants/Variants';
import HowItWIll from "./HowItWIll/HowItWIll";
import Installment from "./Installment/Installment";
import ReviewsSlider from './ReviewsSlider/ReviewsSlider';
import FAQ from './../FAQ/FAQ';
import { useState } from 'react';
import OrderModal from "../../module/OrderModal/OrderModal";
import ChaosComponent from './../Chaos/ChaosComponent';
import ReviewVideos from "../ReviewVideos/ReviewVideos";

const PracticComponent = ({setCurrentLink}) => {
    const [isOpen,setIsOpen] = useState(false)
    return (
        <>
            <BreathMagician setCurrentLink ={setCurrentLink} setIsOpen={setIsOpen} />
            <ChaosComponent setCurrentLink ={setCurrentLink}/>
            <HowItWork  setIsOpen={setIsOpen} />
            <Video />
            <Variants setCurrentLink={setCurrentLink} setIsOpen={setIsOpen}  />
            
            <Installment setIsOpen={setIsOpen}/>
            <Result setCurrentLink={setCurrentLink}  />
            <Expert setCurrentLink={setCurrentLink} setIsOpen={setIsOpen} />
            
           
            <HowItWIll setIsOpen={setIsOpen} />
            <ReviewVideos/>
            <ReviewsSlider setCurrentLink={setCurrentLink}/>
            <FAQ setCurrentLink={setCurrentLink} setIsOpen ={setIsOpen}/>
            {isOpen && <OrderModal close={setIsOpen}/>}
        </>
    );
};

export default PracticComponent;