import { useInView } from "react-cool-inview";
import s from "./Result.module.scss"

const Result = ({setCurrentLink}) => {
    const { observe, unobserve, inView, scrollDirection, entry } = useInView({
        threshold: 0.25, 
        onChange: ({ inView, scrollDirection, entry, observe, }) => {
        
          observe();
        },
        onEnter: ({ scrollDirection, entry, observe, unobserve }) => {
          setCurrentLink('результаты')
        },
      });
    
    return (
        <section ref={observe} id="results" className={s.result}>
            <div className={s.container}>
                <div className={s.wrap}>
                   
                    <div className={s.title}>
                             Как изменится ваша жизнь <br/>
                                после регулярной практики?
                    </div>
                    <div className={s.resultContent}>
                        <div className={s.resultContentImg}>
                            <img src={'/img/resultImg.png'} className={s.img} alt='result'/>
                        </div>
                        <div className={s.resultContentInfo}>
                            
                            <ul>
                                <li>
                                    Вы обретете внутреннее спокойствие, сфокусируетесь на том, что <br/> действительно
                                    важно, и будете действовать продуктивно, даже если <br/> вокруг хаос
                                </li>
                                <li>
                                    Вы увидите новые финансовые возможности, которые скрыты от <br/> большинства людей
                                </li>
                                <li>
                                    Вы начнете смело строить планы, ставить реалистичные цели и с <br/> удовольствием
                                    достигать их
                                </li>
                                <li>
                                    Решатся внутренние конфликты, уйдут претензии к окружающим <br/> людям (родители,
                                    коллеги), наладятся отношения со второй <br/> половиной
                                </li>
                                <li>
                                    Уйдет перманентное психическое напряжение, вы станете более <br/> собранным,
                                    эффективным и результативным
                                </li>
                                <li>
                                    У вас высвободится огромное количество жизненной силы для <br/> активной работы
                                    и творческих задач
                                </li>
                            </ul>
                        </div>
                    </div>
                   
                </div>
            </div>
        </section>
    );
};

export default Result;