import s from "./Expert.module.scss"
import { useInView } from 'react-cool-inview';

const Expert = ({setCurrentLink}) => {
    const {observe } = useInView({
        threshold: 0.25, 
        onChange: ({ observe, }) => {
        
          observe();
        },
        onEnter: () => {
          setCurrentLink('эксперт')
        },
      });
    return (
        <>
        <section ref={observe} id="expert" className={s.expert}>

                <div className={s.wrap}>
                    <div className={s.title}>
                        Ведущий практикума: <span>Роман Карловский</span>
                    </div>
                    <div className={s.secondTitle}>
                    Автор метода Энергодыхание, основатель международного Центра Энергодыхания, 60+ филиалов по всему миру!
                    </div>
                </div>
           

            <div className={s.expertContent}>
                <div className={s.expertContentInfo}>
                    <ul>
                        <li>
                        Эксперт по дыхательным практикам с опытом более 15 лет, разработал корпоративные программы по Энергодыханию для Газпромнефть, Ростелеком, Х5 ритейл групп.
                        </li>
                        <li>
                         Исследователь медитативных состояний Самадхи, Сатори. Доказал и зафиксировал феномен энергии Сома в теле. Персональный проводник в состояние Самадхи (лично провёл более 40 человек в самадхи).
                        </li>
                        <li>
                        Писатель. Режиссёр и автор фильмов про высший уровень медитации (Сатори / Самадхи) и популяризатор наивысшего духовного пути в социальном мире.
                        </li>
                        <li>
                        Сотрудничает с Институтом им. Бехтерова г. Москва. Совместные исследования в области энерго дыхания: влияние на организм и применение в социальной жизни.
                        </li>
                       
                    </ul>
                </div>
             
            </div>
            <div className={s.MobileExpert}>
                <img src="/img/MobileExpert.png" alt="expert"/>
                <ul>
                        <li>
                        Эксперт по дыхательным практикам с опытом более 15 лет, разработал корпоративные программы по Энергодыханию для Газпромнефть, Ростелеком, Х5 ритейл групп.
                        </li>
                        <li>
                         Исследователь медитативных состояний Самадхи, Сатори. Доказал и зафиксировал феномен энергии Сома в теле. Персональный проводник в состояние Самадхи (лично провёл более 40 человек в самадхи).
                        </li>
                        <li>
                        Писатель. Режиссёр и автор фильмов про высший уровень медитации (Сатори / Самадхи) и популяризатор наивысшего духовного пути в социальном мире.
                        </li>
                        <li>
                        Сотрудничает с Институтом им. Бехтерова г. Москва. Совместные исследования в области энерго дыхания: влияние на организм и применение в социальной жизни.
                        </li>
                       
                    </ul>
            </div>
            {/* <div className={s.wrap}>
                    <div className={s.title}>
                    Мастер ресурсного состояния: <span>Игорь Сивов</span>
                    </div>
                    <div className={s.secondTitle}>
                    Приглашенный ведущий тренер энергодыхания 
                    </div>
                </div>
            */}

            {/* <div className={s.anotherExpertContent}>
                <div className={s.expertContentInfo}>
                    <ul>
                        <li>
                        Мастер ресурсного состояния
                        </li>
                        <li>
                        Магистр психоанализа
                        </li>
                        <li>
                        Автор концепции «PROбуждение», который прошли более 5000 человек
                        </li>
                        <li>
                        Автор сообщества Пробуждение, в котором более 1000 участников
                        </li>
                        <li>
                        Многодетный отец, 4 детей
                        </li>
                        <li>
                        Медийный блогер с аудиторией более полумиллиона подписчиков
                        </li>
                        <li>
                        Основатель культуры осознанного родительства в России
                        </li>
                        <li>
                        Автор книги «Ненавредители»
                        </li>
                       
                    </ul>
                </div>
             
            </div>
            <div className={s.MobileExpert}>
                <img src="/img/anotherMobileExpert.png" alt="expert"/>
                <ul>
                        <li>
                        Мастер ресурсного состояния
                        </li>
                        <li>
                        Магистр психоанализа
                        </li>
                        <li>
                        Автор концепции «PROбуждение», который прошли более 5000 человек
                        </li>
                        <li>
                        Автор сообщества Пробуждение, в котором более 1000 участников
                        </li>
                        <li>
                        Многодетный отец, 4 детей
                        </li>
                        <li>
                        Медийный блогер с аудиторией более полумиллиона подписчиков 
                        </li>
                        <li>
                        Основатель культуры осознанного родительства в России
                        </li>
                        <li>
                        Автор книги «Ненавредители»
                        </li>
                       
                    </ul> */}
            {/* </div> */}
        </section>
        </>
    );
};

export default Expert;