import s from './Header.module.scss'
import MobileHeader from './MobileHeader/MobileHeader'

export default function Header({currentLink}) {
    const links = [
    
    
    { 
            name : 'главная',
            link : '#main'
    },
    { 
        name : 'программа практикума',
        link : '#program'
    },
    { 
        name : 'варианты участия',
        link : '#variants'
    },
    { 
        name : 'результаты',
        link : '#results'
    },
    { 
        name : 'эксперт',
        link : '#expert'
    },
   
    { 
        name : 'отзывы',
        link : '#reviews'
    },
    { 
        name : 'faq',
        link : '#faq'
    },]
    const Up = () => {
        window.scrollTo( {
            top : 0,
            behavior : 'smooth',
        })
    }
    return (
        <><header className={s.header}>
            <img onClick={() => Up()} className={s.logo} src="/img/icons/angel.svg" alt="" />
            <div className={s.container}>
                {links.map((i, idx) => (
                    <a href={i.link} key={i.name} className={currentLink === i.name? s.activeLink : s.link}>
                        {i.name}
                    </a>
                ))}
                <div className={s.social}>
                    <a target='_blank' rel="noreferrer" href="https://t.me/energybreathing">
                        <img src={'/img/icons/orangeTelegram.svg'} alt={'telegram'} />
                    </a>
                    <a target='_blank' rel="noreferrer" href="https://vk.com/practica_for_me">
                        <img src={'/img/icons/orangeVK.svg'} alt={'vk'} />
                    </a>
                </div>
            </div>
        </header><MobileHeader currentLink={currentLink} /></>
    )
}