import s from "./WhatWeFeel.module.scss"

const WhatWeFeel = () => {
    return (
        <div className={s.WhatWeFeel}>
            <div className={s.header}>
                <div className={s.MainTitle} >
                    <h1 className={s.title}>Что мы чувствуем, когда вокруг кризис?</h1>
                    <p>Страх, Апатию, Уныние, Тревогу, Опустошение</p>
                </div>
            </div>

            <div className={ s.Information}>
                <div className={s.mainInfo}>
                    <div className={s.img}>
                        <img src={'/img/depressia.png'} alt={'depression'}/>
                    </div>
                    <div className={s.mainInfoText}>
                        <div className={s.mainInfoTitle}>
                            <p>В этот момент наша <br /> жизненная энергия <br /> сливается бесконтрольно</p>
                        </div>
                        <ul>
                            <li>
                                Вместо важных задач листаем новостную ленту
                            </li>
                            <li>
                                Цели, которые ставили вчера, сегодня кажутся недостижимыми
                            </li>
                            <li>
                            В отношениях чувствуется напряжение и недосказанность
                            </li>
                            <li>
                                Страшно строить планы, когда не знаешь, что будет завтра
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={s.text}>
                    В этот момент мир незаметно уходит из под ног.
                    <br/>
                    Вы, сами того не замечая, лишаетесь опоры — энергии, которая раньше двигала вас вперед и
                    помогала ясно смотреть в будущее. <br />
                    Включается “режим выживания”. Отсутствие энергии погружает в рутину,
                    вы застываете и забываете о глобальных целях.
                </div>
            </div>
        </div>
    );
};

export default WhatWeFeel;