import s from "./ViciousCircle.module.scss"
import { useInView } from 'react-cool-inview';

const ViciousCircle = ({setCurrentLink}) => {
    const { observe } = useInView({
        threshold: 0.25, 
        onChange: ({ observe, }) => {
        
          observe();
        },
        onEnter: () => {
          setCurrentLink('программа практикума')
        },
      });
    return (
        <div className={s.ViciousCircle}>
            
            <div className={s.header}>
                <div className={s.container}>
                    <div className={s.title}>
                        Это похоже на замкнутый круг
                    </div>
                    <ul>
                        <li>
                         Работать не хочется и не получается
                        </li>
                        <li>
                        Творческие задачи выполняются плохо
                        </li>
                        <li>
                        Финансов и способов заработать становится меньше
                        </li>
                        <li>
                        Всплывают хронические болезни, здоровье ухудшается на глазах
                        </li>
                    </ul>
                </div>
            </div>
            <div >
                <div  ref={observe} id="program"  className={s.title}>
                    Разорвите этот замкнутый круг
                </div>
                <p className={s.fill}>Наполните энергией свою жизнь на практикуме <span>Маг Дыхания!</span></p>
               
                <div    className={s.download}>
                    <a href="/files/Программа практикума.pdf" download>Скачать программу практикума</a>
                </div>
            </div>
        </div>
    );
};

export default ViciousCircle;